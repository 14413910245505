// src/App.js
import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import './App.css';

const App = () => {
  const [keySequence, setKeySequence] = useState([]);
  const [showGitHubLink, setShowGitHubLink] = useState(false);
  const [powerOn, setPowerOn] = useState(false);
  const [flicker, setFlicker] = useState(false);
  const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

  useEffect(() => {
    const handleKeyDown = (event) => {
      setKeySequence((prevSequence) => [...prevSequence, event.key].slice(-10));
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [powerOn]);

  useEffect(() => {
    if (JSON.stringify(keySequence) === JSON.stringify(konamiCode)) {
      //alert('Konami Code Activated!');
      setShowGitHubLink(true);
    }
  }, [keySequence]);

  const handlePowerOn = () => {
    setPowerOn(true);
  }

  useEffect(() => {
    if(powerOn){
      setFlicker(true);
      setTimeout(() => {
        setFlicker(false);
        const sound = new Howl({
          src: ['./title_song.mp3'],
          autoplay: true,
          loop: false,
        });
      }, 300); // flicker duration
    }
  }, [powerOn]);
  
  return (
    <div className="App">
      {flicker && <div className="flicker"></div>}
      {!powerOn ? (
        <div className="power-on-button" onClick={handlePowerOn}>
          Power
        </div>
      ) : (
      <div className="contra-loading-screen animate">
          <h1>ERIC HALVORSEN</h1>
          <p>Halvorsen Software Consulting, LLC</p>
          <div className="player-select">
            <div className="player"><a href="https://www.linkedin.com/in/eric-halvorsen/">LINKEDIN</a></div>
            <div className="player"><a href="./resume.html">RESUME</a></div>
          </div>
          {showGitHubLink && (
            <div className="player-select-secret">
              <div className="player"><a href="http://github.com/eh7n">GITHUB (+30)</a></div>
            </div>
            )}
        </div>
      )}
    </div>
  );
};

export default App;